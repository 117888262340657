import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import DLeft from '../images/l-l.png'
import DRight from '../images/l-r.png'
import product from '../images/product.png'
import product2 from '../images/shapo.png'
import shapoo from '../images/smear.png'
import shapoo2 from '../images/smear-2.png'
import vitamin from '../images/vitamin.png'
import Splash from '../images/splash-2.png'
import wash from '../images/wash.png'
import vid from '../images/video.png'
import { Footer } from '../components/Footer'

const HowToPage = () => {
  useEffect(() => {
    document.title = "How To Use | Lusttress";
  }, [])
  
  return (
    <div>
      <Navbar />
      <section className="flex mt-[20px] md:mt-[-40px] justify-between items-center">
        <img src={DLeft} alt="Left Image" className="w-[60px] md:w-[200px]" />
        <div className="absolute left-1/2 w-full transform -translate-x-1/2 text-center">
          <h2  data-aos="zoom-in"  className=" text-[14px]  md:text-[32px]">HOW TO MAKE YOUR</h2>
          <h1 className="frank text-[22px] md:text-[44px] font-bold">Lust Tres Customize Shampoo</h1>
          <h5 className="md:text-[20px] text-[8px]">Don’t forget to <span className='text-[#1C6047] font-bold'>take a quiz</span> so you can match the right one for your hair!</h5>
        </div>
        <img src={DRight} alt="Right Image" className="w-[60px] md:w-[200px]" />
      </section>

      <section className="relative">
        <div className="absolute bg-[#FAFAFA] w-full h-[327px] z-0 top-[3rem]"></div>
        <div className="flex flex-col md:flex-row relative z-10 ">
          <div className='p-5 md:p-[0px]'>
            <img src={product} alt="Product Image" className="z-10 rounded-tl-[75px] md:rounded-none w-full md:w-full md:w-auto" />
          </div>
          <div className=" md-[2rem] md:pr-[11rem] mx-3 pl-[1rem] md:pl-[5rem] py- md:py-[5rem] mt-[1rem] z-10">
            <h3 className="uppercase text-[14px] md:text-[24px] tracking-widest" data-aos="fade-up" >Step 1</h3>
            <h2  data-aos="zoom-in" className=" text-[20px] md:text-[30px] frank">
              Get our base <span className="text-primary font-semibold">Shampoo or Conditioner</span>
            </h2>
          </div>
          <div className="absolute right-[0] md:right-1 mt-[16rem] md:mt-12 z-20">
            <img src={shapoo} alt="Shampoo Image" className="w-[120px] md:w-[350px]" />
          </div>
        </div>
      </section>
      <section>
        <div className='flex mt-5 md:mt-0 flex-col md:flex-row '>
          <div className='w-[24rem] md:hidden md:w-full'>
            <img src={vitamin} alt='vitamin' className="md:w-auto" />
          </div>
          <div className='my-[2rem] mx-6 md:my-[10rem] pl-[0] md:pl-[4rem]  md:mx-12'>
            <h3 className="uppercase md:text-[24px] text-[14px] tracking-widest	" data-aos="fade-up">Step 2</h3>
            <h2 className="text-[20px] md:text-[34px] frank" data-aos="zoom-in">
              Put <span className="text-primary font-semibold">Boosters and Fragrances </span>
              into the base</h2>
          </div>
          <div className='w-[24rem] hidden md:block md:w-full'>
            <img src={vitamin} alt='vitamin' className="md:w-auto" />
          </div>
        </div>
      </section>

      <section className='relative'>
        <div className="absolute bg-[#FAFAFA] w-full h-[327px] z-0 top-[3rem]"></div>
        <div className="flex flex-col items-center md:flex-row relative z-10">
          <div className='md:w-[36rem] w-auto p-0 flex justify-center md:block'>
            <img src={product2} alt="Product Image" className="z-10 w-[20rem] rounded-tl-[3rem] rounded-bl-[3rem] md:rounded-bl-[0] md:rounded-tl-[0] md:w-full md:w-auto" />
          </div>
          <div className="pl-[1rem] md:pl-[5rem] py-[1rem] md:py-[5rem] pr-5 md:pr-12 mt-[1rem] md:mt-[0rem] z-10 text-center md:text-left">
            <h3 data-aos="fade-up" className="uppercase text-[14px] md:text-[22px] tracking-widest">Step 3</h3>
            <h2 className="text-[20px] md:text-[34px] pr-[0] md:pr-[12rem] frank" data-aos="zoom-in">
              Shake shake shake to mix <span className="text-primary font-semibold">Base, Booster and Fragrance</span>
            </h2>
          </div>
          <div className="absolute top-[26rem] md:top-[0] w-auto z-20 flex right-[0rem] md:right-0">
            <img src={shapoo2} alt="Shampoo Image" className="w-[100px] md:w-auto" />
          </div>

        </div>
      </section>
      <section>
        <div className='flex flex-col mt-[6rem] mb-4 md:mb-0 md:mt-0 md:flex-row items-center'>
          <div className='hidden md:block'><img src={Splash} className=" w-[200px] md:w-full md:w-auto" /></div>
          <div className='ml-5  md:ml-[-10rem] mr-5 md:mr-[8rem]'>
            <h3 data-aos="fade-up" className="uppercase  text-[14px] md:text-[24px] tracking-widest	">Step 4</h3>
            <ul data-aos="fade-right" className='frank list-disc text-[20px] md:text-[30px] pl-12'>
              <li>Use <span className='text-[#1C6047] font-bold'>Shampoo and Conditioner</span> on wet hair.</li>
              <li>Rinse Shampoo and Conditioner.</li>
              <li>Repeat the process until hair is clean, and you’re done!</li>
            </ul>
          </div>
          <div>
            <img src={wash} width="532px" className="w-[250px] md:w-auto rounded-tr-[2rem] mt-5 rounded-br-[2rem]" />
          </div>
        </div>
      </section>
      <section className='flex flex-col md:flex-row items-center justify-between rounded-[18px] bg-[#FAFAFA] px-6 md:px-12 py-[4rem] mx-[2rem] md:mx-[5rem] mb-[5rem]'>
        <div className='pr-[1rem] md:pr-[3rem]'>
          <h1 className='frank text-[20px] md:text-[36px] font-semibold'>
            Master Your Hair Care Routine
          </h1>
          <p data-aos="zoom-in" className='text-[12px] md:text-[18px]'>
            Need a visual guide? Watch our step-by-step video guide to learn how to use our products effectively to achieve professional results at home.
          </p>
        </div>
        <div className=' w-[17rem] mt-3 md:mt-0 md:w-[150rem]'>
          <img src={vid} className="w-full md:w-auto" />
        </div>
      </section>
      <Footer />
    </div>

  )
}

export default HowToPage
