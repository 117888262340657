import AOS from "aos";
import "aos/dist/aos.css";
import "../css/style.css";
import bg1 from "../images/bg-1.png";
import bg2 from "../images/bg-2.png";
import goTo from "../images/go-to.png";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ResultPage = () => {
  AOS.init();
  const navigate = useNavigate();

  const name = localStorage.getItem("name");
  const firstName = name?.split(" ")[0];

  const scalpType = localStorage.getItem("question_0");
  const scalpTypeObj = JSON.parse(scalpType);

  const hairType = localStorage.getItem("question_1");
  const hairTypeObj = JSON.parse(hairType);

  const mainType = localStorage.getItem("question_9");
  const mainTypeObj = JSON.parse(mainType);
  const mainTypeAnswer = mainTypeObj?.answers;

  const mainTypeDescriptions = {
    0: "Hair Loss",
    1: "Voluminous Hair",
    2: "Dry Scalp",
    3: "Hair Dye",
    4: "Dry Hair",
    5: "Split Ends",
    6: "Healthy and Shiny",
    7: "Grow Quick",
  };

  const boosterMapShampo = {
    "0,1,3": "https://shopee.co.id/product/1171070080/24034147067/",
    "0,2,3": "https://shopee.co.id/product/1171070080/24034147067/",
    "0,3,4": "https://shopee.co.id/product/1171070080/24034147067/",
    "0,3,5": "https://shopee.co.id/product/1171070080/24034147067/",
    "0,3,6": "https://shopee.co.id/product/1171070080/24034147067/",
    "0,3,7": "https://shopee.co.id/product/1171070080/24034147067/",
    "2,3,7": "https://shopee.co.id/product/1171070080/24034147067/",
    "3,4,7": "https://shopee.co.id/product/1171070080/24034147067/",
    "3,5,7": "https://shopee.co.id/product/1171070080/24034147067/",
    "3,6,7": "https://shopee.co.id/product/1171070080/24034147067/",

    "0,1,4": "https://shopee.co.id/product/1171070080/28056615216/",
    "0,1,5": "https://shopee.co.id/product/1171070080/28056615216/",
    "0,2,4": "https://shopee.co.id/product/1171070080/28056615216/",
    "0,2,5": "https://shopee.co.id/product/1171070080/28056615216/",
    "0,2,7": "https://shopee.co.id/product/1171070080/28056615216/",
    "0,4,7": "https://shopee.co.id/product/1171070080/28056615216/",
    "0,5,7": "https://shopee.co.id/product/1171070080/28056615216/",
    "2,4,7": "https://shopee.co.id/product/1171070080/28056615216/",
    "2,5,7": "https://shopee.co.id/product/1171070080/28056615216/",
    "2,6,7": "https://shopee.co.id/product/1171070080/28056615216/",
    "4,5,7": "https://shopee.co.id/product/1171070080/28056615216/",

    "0,1,2": "https://shopee.co.id/product/1171070080/28106612702/",
    "0,1,6": "https://shopee.co.id/product/1171070080/28106612702/",
    "0,1,7": "https://shopee.co.id/product/1171070080/28106612702/",
    "0,2,6": "https://shopee.co.id/product/1171070080/28106612702/",
    "0,4,6": "https://shopee.co.id/product/1171070080/28106612702/",
    "0,5,6": "https://shopee.co.id/product/1171070080/28106612702/",
    "0,6,7": "https://shopee.co.id/product/1171070080/28106612702/",
    "1,2,7": "https://shopee.co.id/product/1171070080/28106612702/",
    "1,4,7": "https://shopee.co.id/product/1171070080/28106612702/",
    "1,5,7": "https://shopee.co.id/product/1171070080/28106612702/",
    "1,6,7": "https://shopee.co.id/product/1171070080/28106612702/",
    "4,6,7": "https://shopee.co.id/product/1171070080/28106612702/",
    "5,6,7": "https://shopee.co.id/product/1171070080/28106612702/",

    "1,2,4": "https://shopee.co.id/product/1171070080/29606615676/",
    "1,2,5": "https://shopee.co.id/product/1171070080/29606615676/",
    "1,2,6": "https://shopee.co.id/product/1171070080/29606615676/",
    "1,4,5": "https://shopee.co.id/product/1171070080/29606615676/",
    "1,4,6": "https://shopee.co.id/product/1171070080/29606615676/",
    "1,5,6": "https://shopee.co.id/product/1171070080/29606615676/",
    "2,4,5": "https://shopee.co.id/product/1171070080/29606615676/",
    "2,4,6": "https://shopee.co.id/product/1171070080/29606615676/",
    "2,5,6": "https://shopee.co.id/product/1171070080/29606615676/",
    "4,5,6": "https://shopee.co.id/product/1171070080/29606615676/",

    "1,2,3": "https://shopee.co.id/product/1171070080/27856615735/",
    "1,3,4": "https://shopee.co.id/product/1171070080/27856615735/",
    "1,3,5": "https://shopee.co.id/product/1171070080/27856615735/",
    "1,3,6": "https://shopee.co.id/product/1171070080/27856615735/",
    "1,3,7": "https://shopee.co.id/product/1171070080/27856615735/",
    "2,3,6": "https://shopee.co.id/product/1171070080/27856615735/",
    "3,4,6": "https://shopee.co.id/product/1171070080/27856615735/",
    "3,5,6": "https://shopee.co.id/product/1171070080/27856615735/",

    "2,3,4": "https://shopee.co.id/product/1171070080/28506615443/",
    "2,3,5": "https://shopee.co.id/product/1171070080/28506615443/",
    "3,4,5": "https://shopee.co.id/product/1171070080/28506615443/",
  };

  const boosterMapConditioner = {
    "0,1,3": "https://shopee.co.id/product/1171070080/26556613610/",
    "0,2,3": "https://shopee.co.id/product/1171070080/26556613610/",
    "0,3,4": "https://shopee.co.id/product/1171070080/26556613610/",
    "0,3,5": "https://shopee.co.id/product/1171070080/26556613610/",
    "0,3,6": "https://shopee.co.id/product/1171070080/26556613610/",
    "0,3,7": "https://shopee.co.id/product/1171070080/26556613610/",
    "2,3,7": "https://shopee.co.id/product/1171070080/26556613610/",
    "3,4,7": "https://shopee.co.id/product/1171070080/26556613610/",
    "3,5,7": "https://shopee.co.id/product/1171070080/26556613610/",
    "3,6,7": "https://shopee.co.id/product/1171070080/26556613610/",

    "0,1,4": "https://shopee.co.id/product/1171070080/28656615949/",
    "0,1,5": "https://shopee.co.id/product/1171070080/28656615949/",
    "0,2,4": "https://shopee.co.id/product/1171070080/28656615949/",
    "0,2,5": "https://shopee.co.id/product/1171070080/28656615949/",
    "0,2,7": "https://shopee.co.id/product/1171070080/28656615949/",
    "0,4,7": "https://shopee.co.id/product/1171070080/28656615949/",
    "0,5,7": "https://shopee.co.id/product/1171070080/28656615949/",
    "2,4,7": "https://shopee.co.id/product/1171070080/28656615949/",
    "2,5,7": "https://shopee.co.id/product/1171070080/28656615949/",
    "2,6,7": "https://shopee.co.id/product/1171070080/28656615949/",
    "4,5,7": "https://shopee.co.id/product/1171070080/28656615949/",

    "0,1,2": "https://shopee.co.id/product/1171070080/24234152051/",
    "0,1,6": "https://shopee.co.id/product/1171070080/24234152051/",
    "0,1,7": "https://shopee.co.id/product/1171070080/24234152051/",
    "0,2,6": "https://shopee.co.id/product/1171070080/24234152051/",
    "0,4,6": "https://shopee.co.id/product/1171070080/24234152051/",
    "0,5,6": "https://shopee.co.id/product/1171070080/24234152051/",
    "0,6,7": "https://shopee.co.id/product/1171070080/24234152051/",
    "1,2,7": "https://shopee.co.id/product/1171070080/24234152051/",
    "1,4,7": "https://shopee.co.id/product/1171070080/24234152051/",
    "1,5,7": "https://shopee.co.id/product/1171070080/24234152051/",
    "1,6,7": "https://shopee.co.id/product/1171070080/24234152051/",
    "4,6,7": "https://shopee.co.id/product/1171070080/24234152051/",
    "5,6,7": "https://shopee.co.id/product/1171070080/24234152051/",

    "1,2,4": "https://shopee.co.id/product/1171070080/26856614139/",
    "1,2,5": "https://shopee.co.id/product/1171070080/26856614139/",
    "1,2,6": "https://shopee.co.id/product/1171070080/26856614139/",
    "1,4,5": "https://shopee.co.id/product/1171070080/26856614139/",
    "1,4,6": "https://shopee.co.id/product/1171070080/26856614139/",
    "1,5,6": "https://shopee.co.id/product/1171070080/26856614139/",
    "2,4,5": "https://shopee.co.id/product/1171070080/26856614139/",
    "2,4,6": "https://shopee.co.id/product/1171070080/26856614139/",
    "2,5,6": "https://shopee.co.id/product/1171070080/26856614139/",
    "4,5,6": "https://shopee.co.id/product/1171070080/26856614139/",

    "1,2,3": "https://shopee.co.id/product/1171070080/26856614139/",
    "1,3,4": "https://shopee.co.id/product/1171070080/26856614139/",
    "1,3,5": "https://shopee.co.id/product/1171070080/26856614139/",
    "1,3,6": "https://shopee.co.id/product/1171070080/26856614139/",
    "1,3,7": "https://shopee.co.id/product/1171070080/26856614139/",
    "2,3,6": "https://shopee.co.id/product/1171070080/26856614139/",
    "3,4,6": "https://shopee.co.id/product/1171070080/26856614139/",
    "3,5,6": "https://shopee.co.id/product/1171070080/26856614139/",

    "2,3,4": "https://shopee.co.id/product/1171070080/29806613697/",
    "2,3,5": "https://shopee.co.id/product/1171070080/29806613697/",
    "3,4,5": "https://shopee.co.id/product/1171070080/29806613697/",
  };

  const boosterMapSpecial = {
    "0,1,3": "https://shopee.co.id/product/1171070080/26006791324/",
    "0,2,3": "https://shopee.co.id/product/1171070080/26006791324/",
    "0,3,4": "https://shopee.co.id/product/1171070080/26006791324/",
    "0,3,5": "https://shopee.co.id/product/1171070080/26006791324/",
    "0,3,6": "https://shopee.co.id/product/1171070080/26006791324/",
    "0,3,7": "https://shopee.co.id/product/1171070080/26006791324/",
    "2,3,7": "https://shopee.co.id/product/1171070080/26006791324/",
    "3,4,7": "https://shopee.co.id/product/1171070080/26006791324/",
    "3,5,7": "https://shopee.co.id/product/1171070080/26006791324/",
    "3,6,7": "https://shopee.co.id/product/1171070080/26006791324/",

    "0,1,4": "https://shopee.co.id/product/1171070080/27556618440/",
    "0,1,5": "https://shopee.co.id/product/1171070080/27556618440/",
    "0,2,4": "https://shopee.co.id/product/1171070080/27556618440/",
    "0,2,5": "https://shopee.co.id/product/1171070080/27556618440/",
    "0,2,7": "https://shopee.co.id/product/1171070080/27556618440/",
    "0,4,7": "https://shopee.co.id/product/1171070080/27556618440/",
    "0,5,7": "https://shopee.co.id/product/1171070080/27556618440/",
    "2,4,7": "https://shopee.co.id/product/1171070080/27556618440/",
    "2,5,7": "https://shopee.co.id/product/1171070080/27556618440/",
    "2,6,7": "https://shopee.co.id/product/1171070080/27556618440/",
    "4,5,7": "https://shopee.co.id/product/1171070080/27556618440/",

    "0,1,2": "https://shopee.co.id/product/1171070080/28456620613/",
    "0,1,6": "https://shopee.co.id/product/1171070080/28456620613/",
    "0,1,7": "https://shopee.co.id/product/1171070080/28456620613/",
    "0,2,6": "https://shopee.co.id/product/1171070080/28456620613/",
    "0,4,6": "https://shopee.co.id/product/1171070080/28456620613/",
    "0,5,6": "https://shopee.co.id/product/1171070080/28456620613/",
    "0,6,7": "https://shopee.co.id/product/1171070080/28456620613/",
    "1,2,7": "https://shopee.co.id/product/1171070080/28456620613/",
    "1,4,7": "https://shopee.co.id/product/1171070080/28456620613/",
    "1,5,7": "https://shopee.co.id/product/1171070080/28456620613/",
    "1,6,7": "https://shopee.co.id/product/1171070080/28456620613/",
    "4,6,7": "https://shopee.co.id/product/1171070080/28456620613/",
    "5,6,7": "https://shopee.co.id/product/1171070080/28456620613/",

    "1,2,4": "https://shopee.co.id/product/1171070080/26506620907/",
    "1,2,5": "https://shopee.co.id/product/1171070080/26506620907/",
    "1,2,6": "https://shopee.co.id/product/1171070080/26506620907/",
    "1,4,5": "https://shopee.co.id/product/1171070080/26506620907/",
    "1,4,6": "https://shopee.co.id/product/1171070080/26506620907/",
    "1,5,6": "https://shopee.co.id/product/1171070080/26506620907/",
    "2,4,5": "https://shopee.co.id/product/1171070080/26506620907/",
    "2,4,6": "https://shopee.co.id/product/1171070080/26506620907/",
    "2,5,6": "https://shopee.co.id/product/1171070080/26506620907/",
    "4,5,6": "https://shopee.co.id/product/1171070080/26506620907/",

    "1,2,3": "https://shopee.co.id/product/1171070080/25834147509/",
    "1,3,4": "https://shopee.co.id/product/1171070080/25834147509/",
    "1,3,5": "https://shopee.co.id/product/1171070080/25834147509/",
    "1,3,6": "https://shopee.co.id/product/1171070080/25834147509/",
    "1,3,7": "https://shopee.co.id/product/1171070080/25834147509/",
    "2,3,6": "https://shopee.co.id/product/1171070080/25834147509/",
    "3,4,6": "https://shopee.co.id/product/1171070080/25834147509/",
    "3,5,6": "https://shopee.co.id/product/1171070080/25834147509/",

    "2,3,4": "https://shopee.co.id/product/1171070080/29356618266/",
    "2,3,5": "https://shopee.co.id/product/1171070080/29356618266/",
    "3,4,5": "https://shopee.co.id/product/1171070080/29356618266/",
  };

  const boosterMapImage1 = {
    "0,1,3": "quiz/booster36/paket-bundling1.png",
    "0,2,3": "quiz/booster36/paket-bundling1.png",
    "0,3,4": "quiz/booster36/paket-bundling1.png",
    "0,3,5": "quiz/booster36/paket-bundling1.png",
    "0,3,6": "quiz/booster36/paket-bundling1.png",
    "0,3,7": "quiz/booster36/paket-bundling1.png",
    "2,3,7": "quiz/booster36/paket-bundling1.png",
    "3,4,7": "quiz/booster36/paket-bundling1.png",
    "3,5,7": "quiz/booster36/paket-bundling1.png",
    "3,6,7": "quiz/booster36/paket-bundling1.png",

    "0,1,4": "quiz/booster38/paket-bundling1.png",
    "0,1,5": "quiz/booster38/paket-bundling1.png",
    "0,2,4": "quiz/booster38/paket-bundling1.png",
    "0,2,5": "quiz/booster38/paket-bundling1.png",
    "0,2,7": "quiz/booster38/paket-bundling1.png",
    "0,4,7": "quiz/booster38/paket-bundling1.png",
    "0,5,7": "quiz/booster38/paket-bundling1.png",
    "2,4,7": "quiz/booster38/paket-bundling1.png",
    "2,5,7": "quiz/booster38/paket-bundling1.png",
    "2,6,7": "quiz/booster38/paket-bundling1.png",
    "4,5,7": "quiz/booster38/paket-bundling1.png",

    "0,1,2": "quiz/booster39/paket-bundling1.png",
    "0,1,6": "quiz/booster39/paket-bundling1.png",
    "0,1,7": "quiz/booster39/paket-bundling1.png",
    "0,2,6": "quiz/booster39/paket-bundling1.png",
    "0,4,6": "quiz/booster39/paket-bundling1.png",
    "0,5,6": "quiz/booster39/paket-bundling1.png",
    "0,6,7": "quiz/booster39/paket-bundling1.png",
    "1,2,7": "quiz/booster39/paket-bundling1.png",
    "1,4,7": "quiz/booster39/paket-bundling1.png",
    "1,5,7": "quiz/booster39/paket-bundling1.png",
    "1,6,7": "quiz/booster39/paket-bundling1.png",
    "4,6,7": "quiz/booster39/paket-bundling1.png",
    "5,6,7": "quiz/booster39/paket-bundling1.png",

    "1,2,4": "quiz/booster89/paket-bundling1.png",
    "1,2,5": "quiz/booster89/paket-bundling1.png",
    "1,2,6": "quiz/booster89/paket-bundling1.png",
    "1,4,5": "quiz/booster89/paket-bundling1.png",
    "1,4,6": "quiz/booster89/paket-bundling1.png",
    "1,5,6": "quiz/booster89/paket-bundling1.png",
    "2,4,5": "quiz/booster89/paket-bundling1.png",
    "2,4,6": "quiz/booster89/paket-bundling1.png",
    "2,5,6": "quiz/booster89/paket-bundling1.png",
    "4,5,6": "quiz/booster89/paket-bundling1.png",

    "1,2,3": "quiz/booster69/paket-bundling1.png",
    "1,3,4": "quiz/booster69/paket-bundling1.png",
    "1,3,5": "quiz/booster69/paket-bundling1.png",
    "1,3,6": "quiz/booster69/paket-bundling1.png",
    "1,3,7": "quiz/booster69/paket-bundling1.png",
    "2,3,6": "quiz/booster69/paket-bundling1.png",
    "3,4,6": "quiz/booster69/paket-bundling1.png",
    "3,5,6": "quiz/booster69/paket-bundling1.png",

    "2,3,4": "quiz/booster68/paket-bundling1.png",
    "2,3,5": "quiz/booster68/paket-bundling1.png",
    "3,4,5": "quiz/booster68/paket-bundling1.png",
  };

  const boosterMapImage2 = {
    "0,1,3": "quiz/booster36/paket-bundling2.png",
    "0,2,3": "quiz/booster36/paket-bundling2.png",
    "0,3,4": "quiz/booster36/paket-bundling2.png",
    "0,3,5": "quiz/booster36/paket-bundling2.png",
    "0,3,6": "quiz/booster36/paket-bundling2.png",
    "0,3,7": "quiz/booster36/paket-bundling2.png",
    "2,3,7": "quiz/booster36/paket-bundling2.png",
    "3,4,7": "quiz/booster36/paket-bundling2.png",
    "3,5,7": "quiz/booster36/paket-bundling2.png",
    "3,6,7": "quiz/booster36/paket-bundling2.png",

    "0,1,4": "quiz/booster38/paket-bundling2.png",
    "0,1,5": "quiz/booster38/paket-bundling2.png",
    "0,2,4": "quiz/booster38/paket-bundling2.png",
    "0,2,5": "quiz/booster38/paket-bundling2.png",
    "0,2,7": "quiz/booster38/paket-bundling2.png",
    "0,4,7": "quiz/booster38/paket-bundling2.png",
    "0,5,7": "quiz/booster38/paket-bundling2.png",
    "2,4,7": "quiz/booster38/paket-bundling2.png",
    "2,5,7": "quiz/booster38/paket-bundling2.png",
    "2,6,7": "quiz/booster38/paket-bundling2.png",
    "4,5,7": "quiz/booster38/paket-bundling2.png",

    "0,1,2": "quiz/booster39/paket-bundling2.png",
    "0,1,6": "quiz/booster39/paket-bundling2.png",
    "0,1,7": "quiz/booster39/paket-bundling2.png",
    "0,2,6": "quiz/booster39/paket-bundling2.png",
    "0,4,6": "quiz/booster39/paket-bundling2.png",
    "0,5,6": "quiz/booster39/paket-bundling2.png",
    "0,6,7": "quiz/booster39/paket-bundling2.png",
    "1,2,7": "quiz/booster39/paket-bundling2.png",
    "1,4,7": "quiz/booster39/paket-bundling2.png",
    "1,5,7": "quiz/booster39/paket-bundling2.png",
    "1,6,7": "quiz/booster39/paket-bundling2.png",
    "4,6,7": "quiz/booster39/paket-bundling2.png",
    "5,6,7": "quiz/booster39/paket-bundling2.png",

    "1,2,4": "quiz/booster89/paket-bundling2.png",
    "1,2,5": "quiz/booster89/paket-bundling2.png",
    "1,2,6": "quiz/booster89/paket-bundling2.png",
    "1,4,5": "quiz/booster89/paket-bundling2.png",
    "1,4,6": "quiz/booster89/paket-bundling2.png",
    "1,5,6": "quiz/booster89/paket-bundling2.png",
    "2,4,5": "quiz/booster89/paket-bundling2.png",
    "2,4,6": "quiz/booster89/paket-bundling2.png",
    "2,5,6": "quiz/booster89/paket-bundling2.png",
    "4,5,6": "quiz/booster89/paket-bundling2.png",

    "1,2,3": "quiz/booster69/paket-bundling2.png",
    "1,3,4": "quiz/booster69/paket-bundling2.png",
    "1,3,5": "quiz/booster69/paket-bundling2.png",
    "1,3,6": "quiz/booster69/paket-bundling2.png",
    "1,3,7": "quiz/booster69/paket-bundling2.png",
    "2,3,6": "quiz/booster69/paket-bundling2.png",
    "3,4,6": "quiz/booster69/paket-bundling2.png",
    "3,5,6": "quiz/booster69/paket-bundling2.png",

    "2,3,4": "quiz/booster68/paket-bundling2.png",
    "2,3,5": "quiz/booster68/paket-bundling2.png",
    "3,4,5": "quiz/booster68/paket-bundling2.png",
  };

  const boosterMapImage3 = {
    "0,1,3": "quiz/booster36/paket-bundling3.png",
    "0,2,3": "quiz/booster36/paket-bundling3.png",
    "0,3,4": "quiz/booster36/paket-bundling3.png",
    "0,3,5": "quiz/booster36/paket-bundling3.png",
    "0,3,6": "quiz/booster36/paket-bundling3.png",
    "0,3,7": "quiz/booster36/paket-bundling3.png",
    "2,3,7": "quiz/booster36/paket-bundling3.png",
    "3,4,7": "quiz/booster36/paket-bundling3.png",
    "3,5,7": "quiz/booster36/paket-bundling3.png",
    "3,6,7": "quiz/booster36/paket-bundling3.png",

    "0,1,4": "quiz/booster38/paket-bundling3.png",
    "0,1,5": "quiz/booster38/paket-bundling3.png",
    "0,2,4": "quiz/booster38/paket-bundling3.png",
    "0,2,5": "quiz/booster38/paket-bundling3.png",
    "0,2,7": "quiz/booster38/paket-bundling3.png",
    "0,4,7": "quiz/booster38/paket-bundling3.png",
    "0,5,7": "quiz/booster38/paket-bundling3.png",
    "2,4,7": "quiz/booster38/paket-bundling3.png",
    "2,5,7": "quiz/booster38/paket-bundling3.png",
    "2,6,7": "quiz/booster38/paket-bundling3.png",
    "4,5,7": "quiz/booster38/paket-bundling3.png",

    "0,1,2": "quiz/booster39/paket-bundling3.png",
    "0,1,6": "quiz/booster39/paket-bundling3.png",
    "0,1,7": "quiz/booster39/paket-bundling3.png",
    "0,2,6": "quiz/booster39/paket-bundling3.png",
    "0,4,6": "quiz/booster39/paket-bundling3.png",
    "0,5,6": "quiz/booster39/paket-bundling3.png",
    "0,6,7": "quiz/booster39/paket-bundling3.png",
    "1,2,7": "quiz/booster39/paket-bundling3.png",
    "1,4,7": "quiz/booster39/paket-bundling3.png",
    "1,5,7": "quiz/booster39/paket-bundling3.png",
    "1,6,7": "quiz/booster39/paket-bundling3.png",
    "4,6,7": "quiz/booster39/paket-bundling3.png",
    "5,6,7": "quiz/booster39/paket-bundling3.png",

    "1,2,4": "quiz/booster89/paket-bundling3.png",
    "1,2,5": "quiz/booster89/paket-bundling3.png",
    "1,2,6": "quiz/booster89/paket-bundling3.png",
    "1,4,5": "quiz/booster89/paket-bundling3.png",
    "1,4,6": "quiz/booster89/paket-bundling3.png",
    "1,5,6": "quiz/booster89/paket-bundling3.png",
    "2,4,5": "quiz/booster89/paket-bundling3.png",
    "2,4,6": "quiz/booster89/paket-bundling3.png",
    "2,5,6": "quiz/booster89/paket-bundling3.png",
    "4,5,6": "quiz/booster89/paket-bundling3.png",

    "1,2,3": "quiz/booster69/paket-bundling3.png",
    "1,3,4": "quiz/booster69/paket-bundling3.png",
    "1,3,5": "quiz/booster69/paket-bundling3.png",
    "1,3,6": "quiz/booster69/paket-bundling3.png",
    "1,3,7": "quiz/booster69/paket-bundling3.png",
    "2,3,6": "quiz/booster69/paket-bundling3.png",
    "3,4,6": "quiz/booster69/paket-bundling3.png",
    "3,5,6": "quiz/booster69/paket-bundling3.png",

    "2,3,4": "quiz/booster68/paket-bundling3.png",
    "2,3,5": "quiz/booster68/paket-bundling3.png",
    "3,4,5": "quiz/booster68/paket-bundling3.png",
  };

  const mainTypeDisplay = mainTypeAnswer
    ?.slice(0, 1)
    .map((answer) => mainTypeDescriptions[answer]);

  const getSortedAnswersKey = (answers) => answers?.sort()?.join(",");

  const getBoosterPackage = (answers) => {
    const key = getSortedAnswersKey(answers);
    return boosterMapShampo[key];
  };

  const getBoosterPackageCondi = (answers) => {
    const key = getSortedAnswersKey(answers);
    return boosterMapConditioner[key];
  };

  const getBoosterPackageSpecial = (answers) => {
    const key = getSortedAnswersKey(answers);
    return boosterMapSpecial[key];
  };

  const getBoosterPackageImage1 = (answers) => {
    const key = getSortedAnswersKey(answers);
    return boosterMapImage1[key];
  };

  const getBoosterPackageImage2 = (answers) => {
    const key = getSortedAnswersKey(answers);
    return boosterMapImage2[key];
  };

  const getBoosterPackageImage3 = (answers) => {
    const key = getSortedAnswersKey(answers);
    return boosterMapImage3[key];
  };

  const boosterPackage = getBoosterPackage(mainTypeAnswer);

  const boosterPackageCondi = getBoosterPackageCondi(mainTypeAnswer);

  const boosterPackageSpecial = getBoosterPackageSpecial(mainTypeAnswer);

  const boosterPackageImage1 = getBoosterPackageImage1(mainTypeAnswer);

  const boosterPackageImage2 = getBoosterPackageImage2(mainTypeAnswer);

  const boosterPackageImage3 = getBoosterPackageImage3(mainTypeAnswer);

  useEffect(() => {
    return () => {
      localStorage.clear();
    };
  }, []);

  useEffect(() => {
    if (!name) {
      navigate("/quiz-lusttress");
    }
  }, [name]);

  return (
    <div>
      <div>
        <img
          src={bg1}
          alt="background"
          className="absolute opacity-10 rotate-[-15deg] top-16 left-[-12px] w-[10rem] lg:w-[30rem] md:w-[16rem]"
        />
        <h1
          className="frank text-[40px] text-center font-medium mt-10"
          data-aos="zoom-in"
          data-aos-easing="linear"
          data-aos-duration="400"
        >
          {firstName?.charAt(0)?.toUpperCase() +
            firstName?.slice(1)?.toLowerCase()}
          , your hair care match is here!
        </h1>
        <div className="flex justify-center mt-2">
          <p
            className="md:text-[20px] text-[17px] mx-4 text-[#808080] jakarta-sans text-center w-[820px]"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="200"
          >
            We have analyzed your quiz responses and found the perfect hair care
            products for you! Let’s explore your personalized recommendations
            based on your concerns.
          </p>
        </div>
        <div
          className="bg-[#f9f9f9] py-6 px-3 sm:px-14 rounded-[20px] w-full max-w-3xl mx-auto mt-12"
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="800"
        >
          <h2 className="text-center frank text-[28px] font-bold text-[#1C6047] mb-4">
            Your hair profile
          </h2>
          <div className="flex justify-between items-center text-center">
            <div>
              <p className="text-[16px] text-[#808080] font-normal mb-1 tracking-wider">
                HAIR TYPE
              </p>
              <p className="text-xl md:text-[24px] font-semibold frank">
                {hairTypeObj?.answer === 0
                  ? "Thick"
                  : hairTypeObj?.answer === 1
                  ? "Normal"
                  : "Thin"}
              </p>
            </div>
            <div className="border-l border-[#808080] h-12"></div>
            <div>
              <p className="text-[16px] text-[#808080] font-normal mb-1 tracking-wider">
                SCALP TYPE
              </p>
              <p className="text-xl md:text-[24px] font-semibold frank">
                {scalpTypeObj?.answer === 0
                  ? "Dry"
                  : hairTypeObj?.answer === 1
                  ? "Normal"
                  : "Oily"}
              </p>
            </div>
            <div className="border-l border-[#808080] h-12"></div>
            <div>
              <p className="text-[16px] text-[#808080] font-normal mb-1 tracking-wider">
                MAIN CONCERN
              </p>
              <p className="text-xl md:text-[24px] font-semibold frank">
                {mainTypeDisplay}
              </p>
            </div>
          </div>
          <p className="text-center jakarta-sans text-[15px] mt-5">
            Frizz is a common issue for curly hair, especially in humid
            conditions. Frizz occurs when the hair's cuticle layer is raised,
            allowing moisture to pass through and swell the hair strands. This
            can make your curls look less defined and more unruly.
          </p>
        </div>
        <div className="relative w-full">
          <img
            src={bg2}
            alt="background"
            className="absolute right-0 lg:mt-[-11.1rem] md:mt-[-0.9rem] mt-[-0.9rem] lg:w-max  w-60 clip-path-bottom"
          />
        </div>
      </div>
      <div className="bg-[#E4FAE2] mt-20 flex flex-col items-center w-full">
        <div className="flex justify-center w-full">
          <h1
            className="frank text-[36px] text-center font-medium pt-16 text-[#1C6047]"
            data-aos="fade-zoom-in"
            data-aos-duration="1500"
          >
            Here is the perfect hair care routine for you
          </h1>
        </div>
        <div className="flex justify-center mt-3 w-full" data-aos="fade-down">
          <p className="md:text-[20px] text-[17px] text-[#808080] jakarta-sans text-center w-[900px] mx-4">
            Say bye-bye to dry and frizzy hair! Below are the two essential
            products that will help you achieve smooth and defined curls, reduce
            frizz, and enhance your hair’s natural beauty.
          </p>
        </div>
        <div className="p-2 mt-11">
          <div className="flex flex-col md:flex-row justify-center gap-16">
            <div className="flex flex-col items-center min-w-[18rem] lg:min-w-[21.6rem]">
              <img
                src={`${process.env.REACT_APP_API_URL}/${boosterPackageImage1}`}
                alt="prod1"
                className="w-full max-w-[21.6rem] h-auto rounded-xl shadow"
                data-aos="fade-up-right"
              />
              <p className="text-[28px] text-[#202020] frank text-center font-medium mt-3">
                Paket Bundling 1
              </p>
              <p className="text-[16px] text-[#808080] jakarta-sans text-center w-[85%] md:w-[330px] mt-2">
                Include 1 shampoo base - 280ml | 2 boosters - 9ml | 1 fragrance
                - 9ml
              </p>
              <a
                target="_blank"
                href={boosterPackage}
                className="bg-[#1C6047] text-white py-2 px-6 rounded-full font-medium mt-9"
                rel="noreferrer"
              >
                Buy product
              </a>
            </div>
            <div className="flex flex-col items-center min-w-[18rem] lg:min-w-[21.6rem]">
              <img
                src={`${process.env.REACT_APP_API_URL}/${boosterPackageImage2}`}
                alt="prod2"
                className="w-full max-w-[21.6rem] h-auto rounded-xl shadow"
                data-aos="fade-up"
              />
              <p className="text-[28px] text-[#202020] frank text-center font-medium mt-3">
                Paket Bundling 2
              </p>
              <p className="text-[16px] text-[#808080] jakarta-sans text-center w-[85%] md:w-[330px] mt-2">
                Include 1 conditioner base - 280ml | 2 boosters - 9ml | 1
                fragrance - 9ml
              </p>
              <a
                target="_blank"
                href={boosterPackageCondi}
                className="bg-[#1C6047] text-white py-2 px-6 rounded-full font-medium mt-10"
                rel="noreferrer"
              >
                Buy product
              </a>
            </div>
            <div className="flex flex-col items-center min-w-[18rem] lg:min-w-[21.6rem]">
              <img
                src={`${process.env.REACT_APP_API_URL}/${boosterPackageImage3}`}
                alt="prod3"
                className="w-full max-w-[21.6rem] h-auto rounded-xl shadow"
                data-aos="fade-up-left"
              />
              <p className="text-[28px] text-[#202020] frank text-center font-medium mt-3">
                Paket Bundling Special
              </p>
              <p className="text-[16px] text-[#808080] jakarta-sans text-center w-[85%] md:w-[330px] mt-2">
                Include 1 shampoo base - 280ml | 1 conditioner base - 280ml | 4
                boosters - 9ml | 2 fragrances - 9ml
              </p>
              <a
                target="_blank"
                href={boosterPackageSpecial}
                className="bg-[#1C6047] text-white py-2 px-6 rounded-full font-medium mt-4"
                rel="noreferrer"
              >
                Buy product
              </a>
            </div>
          </div>
        </div>

        <hr class="border-t border-[#1C6047] lg:w-[70rem] md:w-[40rem] w-[20rem] mt-[4.5rem] mb-14" />
        <div className="flex justify-center">
          <p className="text-[16px] px-16 mb-1 jakarta-sans text-center">
            Want to see more products that suit your hair needs?
          </p>
        </div>
        <div className="flex justify-center mt-1">
          <a
            target="_blank"
            href="https://shopee.co.id/lusttres.id"
            class="text-[16px] jakarta-sans text-[#1C6047] font-semibold hover:underline"
            rel="noreferrer"
          >
            <div className="flex">
              Browse all of our products
              <img src={goTo} alt="go-to" className="mt-1 ms-1" />
            </div>
          </a>
        </div>
        <div className="h-14"></div>
      </div>
    </div>
  );
};

export default ResultPage;
